<template>
  <div>
      <!-- Header section -->
      <el-row :gutter="0">
          <el-col :span="20" :offset="2">
              <header class="page-header">
                  <el-row :gutter="20" align="middle">
                    <el-col :span="12">
                        <h2>Хэрэглэгчийн жагсаалт</h2>
                    </el-col>
                    <el-col :span="12">
                        <div class="text-right">
                                <router-link to="/userList">
                                    <el-button type="success" class="item">Хэрэглэгч нэмэх</el-button>
                                </router-link>
                        </div>
                    </el-col>
                  </el-row>
              </header>
              <!-- Core content -->
              <div class="panel tabled">
                  <el-tabs v-model="activeTabStatus">
                      <el-tab-pane label="Хэрэглэгч" name="all">
                          <div class="panel-content">
                              <div class="pl20 pr20 table-filter mb10">
                                  <el-row :gutter="10" align="middle" type="flex">
                                      <el-col :span="20">
                                          <el-input placeholder="Хайх" class="input-with-select" v-model="search">
                                              <el-button icon="el-icon-search" slot="prepend"></el-button>
                                          </el-input>
                                      </el-col>
                                      <el-col :span="4" class="text-right">
                                          <el-button type="success" plain @click="changPasswordDialog = true">Нууц үг солих</el-button>
                                      </el-col>
                                      <el-col :span="4" class="text-right">
                                          <el-button type="success" plain @click="groupAddUserDialog = true">Группт нэмэх</el-button>
                                      </el-col>
                                      <el-col :span="4" class="text-right">
                                          <el-dropdown @command="handleSort">
                                              <el-button type="default" class="lowercase">Эрэмбэлэх <i class="el-icon-sort"></i></el-button>
                                              <el-dropdown-menu slot="dropdown">
                                                  <el-dropdown-item command = 'firstChar'>
                                                      Нэр А - Я
                                                  </el-dropdown-item>
                                                  <el-dropdown-item command = 'lastChar'>
                                                      Нэр Я - А
                                                  </el-dropdown-item>
                                                  <el-dropdown-item command = 'newest'>
                                                      Шинэ эхэнд
                                                  </el-dropdown-item>
                                                  <el-dropdown-item command = 'oldest'>
                                                      Хуучин эхэнд
                                                  </el-dropdown-item>
                                              </el-dropdown-menu>
                                          </el-dropdown>
                                      </el-col>
                                  </el-row>
                              </div>
                          </div>
                          <div class="panel-content">
                            <el-table
                            :data="userList ? userList.filter(data => !search || data.brand_monName.toLowerCase().includes(search.toLowerCase())) : null"
                            style="width: 100%"
                            v-loading="isLoading"
                            >
                            <el-table-column label="Нэвтрэх нэр"
                              prop="Username"
                            ></el-table-column>
                            <el-table-column label="Төлөв"
                              prop="UserStatus"
                            ></el-table-column>
                            <el-table-column label="И-мэйл хаяг">
                              <template slot-scope="scope">
                                <span>{{scope.row.Attributes[0].Value}}</span>
                              </template>
                             </el-table-column>
                             <el-table-column>
                                <el-button type="primary" size="mini">Confirm</el-button>
                             </el-table-column>
                             <!-- <el-table-column>
                                <el-button type="primary" size="small" @click="changPasswordDialog = true">Нууц үг солих</el-button>
                             </el-table-column> -->
                            </el-table>
                            <el-pagination
                              @current-change = "curentPageHandler"
                              @size-change = "sizeChangeHandler"
                              class="text-right mt10"
                              background
                              :current-page = "page"
                              :page-size="pageSize"
                              :page-sizes="[5, 20, 50, 100]"
                              layout="total, sizes, prev, pager, next"
                              :total="totalCount">
                            </el-pagination>
                          </div>
                      </el-tab-pane>
                      <el-tab-pane label="Групп" name="group">
                          <div class="panel-content">
                              <div class="pl20 pr20 table-filter mb10">
                                  <el-row :gutter="10" align="middle" type="flex">
                                      <el-col :span="16">
                                          <el-input placeholder="Хайх" class="input-with-select" v-model="search">
                                              <el-button icon="el-icon-search" slot="prepend"></el-button>
                                          </el-input>
                                      </el-col>
                                      <el-col :span="4" :offset="2" class="text-right">
                                          <el-button type="success" plain @click="createGroupDialog = true">Групп нэмэх</el-button>
                                      </el-col>
                                      <el-col :span="4" class="text-right">
                                          <el-dropdown @command="handleSort">
                                              <el-button type="default" class="lowercase">Эрэмбэлэх <i class="el-icon-sort"></i></el-button>
                                              <el-dropdown-menu slot="dropdown">
                                                  <el-dropdown-item command = 'firstChar'>
                                                      Нэр А - Я
                                                  </el-dropdown-item>
                                                  <el-dropdown-item command = 'lastChar'>
                                                      Нэр Я - А
                                                  </el-dropdown-item>
                                                  <el-dropdown-item command = 'newest'>
                                                      Шинэ эхэнд
                                                  </el-dropdown-item>
                                                  <el-dropdown-item command = 'oldest'>
                                                      Хуучин эхэнд
                                                  </el-dropdown-item>
                                              </el-dropdown-menu>
                                          </el-dropdown>
                                      </el-col>
                                  </el-row>
                              </div>
                          </div>
                          <div class="panel-content">
                            <el-table
                            :data="groupList ? groupList.filter(data => !search || data.GroupName.toLowerCase().includes(search.toLowerCase())) : null"
                            style="width: 100%"
                            v-loading="isLoading"
                            >
                            <el-table-column label="Групп нэр"
                            >
                             <template slot-scope="scope">
                              <span>{{scope.row.GroupName}}</span>
                             </template>
                             </el-table-column>
                             <el-table-column label="Тайлбар"
                            >
                             <template slot-scope="scope">
                              <span>{{scope.row.Description}}</span>
                             </template>
                             </el-table-column>
                             <el-table-column>
                             <template slot-scope="scope">
                               <el-button @click="deleteGroup(scope.row)" type="danger" icon="el-icon-delete" size="small" circle></el-button>
                             </template>
                             </el-table-column>
                            </el-table>
                            <el-pagination
                              @current-change = "curentPageHandler"
                              @size-change = "sizeChangeHandler"
                              class="text-right mt10"
                              background
                              :current-page = "page"
                              :page-size="pageSize"
                              :page-sizes="[5, 20, 50, 100]"
                              layout="total, sizes, prev, pager, next"
                              :total="totalCount">
                            </el-pagination>
                          </div>
                      </el-tab-pane>
                  </el-tabs>
              </div>
          </el-col>
      </el-row>
      <el-dialog
      title="Групп үүсгэх"
      :visible.sync="createGroupDialog"
      width="50%"
      >
      <el-form label-position="top" :model="groupData" ref="groupData" :rules="groupRules">
        <el-form-item label="Группын нэр" prop="groupName">
          <el-input v-model="groupData.groupName" placeholder="Админ"></el-input>
        </el-form-item>
        <el-form-item label="Тайлбар" prop="description">
          <el-input v-model="groupData.description" type="textarea" rows="3" placeholder="Энэ зөвхөн админуудын групп гэх мэт ..."></el-input>
        </el-form-item>
        <div class="text-right">
          <el-button @click="createGroupDialog = false">Болих</el-button>
          <el-button type="success" @click="createGroup('groupData')">Үүсгэх</el-button>
        </div>
      </el-form>
      </el-dialog>
      <el-dialog
      title="Групп устгах"
      :visible.sync="confirmDeleteGroupDialog"
      width="50%"
      >
      <div>
        <strong>{{ groupData.groupName }}</strong> группыг устгахдаа итгэлтэй байна уу?
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmDeleteGroupDialog = false" class="mr10"
          >Болих</el-button
        >
        <el-button type="danger" @click="deleteGroup(groupData)">
          Устгах
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="Нууц үг солих"
      :visible.sync="changPasswordDialog"
      width="30%"
      >
      <el-form label-position="top" :model="passwordData" ref="passwordData" :rules="passwordRules">
        <el-form-item label="Хэрэглэгч" prop="userName">
          <el-input v-model="passwordData.userName" prefix-icon="el-icon-s-custom" placeholder="tulgaa"></el-input>
        </el-form-item>
        <el-form-item label="Шинэ нууц үг" prop="password">
          <el-input v-model="passwordData.password" prefix-icon="el-icon-unlock" type="password" placeholder="123456 ..."></el-input>
        </el-form-item>
        <el-form-item label="Нууц үг давтах" prop="passwordConfirm">
          <el-input v-model="passwordData.passwordConfirm" prefix-icon="el-icon-unlock" type="password" placeholder="123456 ..."></el-input>
        </el-form-item>
        <div class="text-right">
          <el-button @click="changPasswordDialog = false">Болих</el-button>
          <el-button type="success" @click="changePassword(passwordData)">Үүсгэх</el-button>
        </div>
      </el-form>
      </el-dialog>
      <el-dialog
      title="Группт нэмэх"
      :visible.sync="groupAddUserDialog"
      width="30%"
      >
      <el-form label-position="top" :model="groupAddUser" ref="groupAddUser">
        <el-form-item label="Хэрэглэгч">
          <el-input v-model="groupAddUser.userName" prefix-icon="el-icon-s-custom" placeholder="tulgaa"></el-input>
        </el-form-item>
        <el-form-item label="Групп">
          <el-select
            v-model="groupAddUser.groupName"
            filterable
            class="block">
            <el-option
               v-for="item in groupList"
               :key="item.GroupName"
               :label="item.GroupName"
               :value="item.GroupName">
            </el-option>
           </el-select>
        </el-form-item>
        <div class="text-right">
          <el-button @click="groupAddUserDialog = false">Болих</el-button>
          <el-button type="success" @click="addUserToGroup(groupAddUser)">Нэмэх</el-button>
        </div>
      </el-form>
      </el-dialog>
  </div>
</template>

<script>
import services from '../../../helpers/services'
export default {
  created () {
    this.getUsers()
    this.getGroups()
  },
  data () {
    return {
      groupAddUserDialog: false,
      changPasswordDialog: false,
      confirmDeleteGroupDialog: false,
      createGroupDialog: false,
      search: '',
      userList: [],
      groupList: [],
      activeTabStatus: 'all',
      isLoading: false,
      pageSize: 20,
      page: 1,
      pageFrom: 0,
      totalCount: 0,
      groupData: {
        groupName: '',
        description: ''
      },
      passwordData: {
        userName: '',
        password: '',
        passwordConfirm: ''
      },
      groupAddUser: {
        userName: '',
        groupName: ''
      },

      groupRules: {
        groupName: [
          { required: true, message: 'Группын нэрээ заавал оруулна уу', trigger: 'blur' }
        ],
        description: [
          { required: true, message: 'Тайлбар заавал оруулна уу', trigger: 'blur' }
        ]
      },

      passwordRules: {
        userName: [
          { required: true, message: 'Хэрэглэгчийн нэр заавал оруулна уу', trigger: 'blur' }
        ],
        password: [
          { required: true, message: 'Нууц үг заавал оруулна уу', trigger: 'blur' }
        ],
        passwordConfirm: [
          { required: true, message: 'Нууц үг заавал оруулна уу', trigger: 'blur' }
        ]
      }
    }
  },

  methods: {
    addUserToGroup (data) {
      services.addUserToGroup(data).then(response => {
        if (response.status === 'success') {
          this.alertReporter('Амжилттай', response.message, response.status)
          this.isLoading = false
          this.groupAddUserDialog = false
        } else if (response.status === 'error') {
          this.alertReporter('Алдаа', response.message, response.status)
        }
      })
    },

    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'bottom-left'
      })
    },

    sizeChangeHandler (item) {
      this.pageSize = item
      this.isLoading = true
      this.getUsers()
    },

    curentPageHandler (item) {
      this.page = item
      this.isLoading = true
      this.getUsers()
    },

    sortMethods (method, data) {
      if (method === 'firstChar') {
        data.sort((a, b) => a.Username.localeCompare(b.Username))
      } else if (method === 'lastChar') {
        data.sort((a, b) => b.Username.localeCompare(a.Username))
      } else if (method === 'newest') {
        data.sort((a, b) => b.UserCreateDate.localeCompare(a.UserCreateDate))
      } else if (method === 'oldest') {
        data.sort((a, b) => a.UserCreateDate.localeCompare(b.UserCreateDate))
      }
      return data
    },

    handleSort (data) {
      this.search = ''
      this.userList = this.sortMethods(data, this.userList)
    },

    getUsers () {
      this.isLoading = true
      // const query = '?search_text=' + this.search + '&from=' + (this.pageSize * (this.page - 1)) + '&size=' + this.pageSize
      services.getUsers().then(response => {
        this.userList = response.Users
        this.totalCount = response.total
        this.isLoading = false
      })
    },

    getGroups () {
      this.isLoading = true
      // const query = '?search_text=' + this.search + '&from=' + (this.pageSize * (this.page - 1)) + '&size=' + this.pageSize
      services.getGroups().then(response => {
        this.groupList = response.Groups
        this.totalCount = response.total
        this.isLoading = false
      })
    },

    createGroup (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.isLoading = true
          this.createGroupDialog = false
          services.createGroup(this.groupData).then(data => {
            if (data.status === 'success') {
              this.alertReporter('Амжилттай', data.message, data.status)
              this.$refs[formName].resetFields()
              this.getGroups()
              this.isLoading = false
            } else if (data.status === 'error') {
              this.alertReporter('Алдаа', data.message, 'error')
              this.$refs[formName].resetFields()
              this.isLoading = false
            }
          })
        }
      })
    },

    deleteGroup (data) {
      this.$confirm('Группыг устгахдаа итгэлтэй байна уу?', 'Групп устгах', {
        confirmButtonText: 'Устгах',
        cancelButtonText: 'Болих',
        type: 'warning'
      }).then(() => {
        services.deleteGroup({ groupName: data.GroupName }).then(response => {
          if (response.status === 'success') {
            this.alertReporter('Амжилттай', `${data.GroupName} нэртэй групп устлаа`, 'success')
            this.isLoading = false
            this.getGroups()
          } else if (response.status === 'error') {
            this.alertReporter('Алдаа', `${response.message}`)
            this.isLoading = false
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Цуцалсан'
        })
      })
    },

    changePassword (data) {
      if (this.passwordData.password !== this.passwordData.passwordConfirm) {
        this.alertReporter('Алдаа', 'Нууц үг таарахгүй байна')
      } else if (this.passwordData.password === this.passwordData.passwordConfirm) {
        services.changePassword(data).then(response => {
          if (response.status === 'success') {
            this.alertReporter('Амжилттай', response.message, response.status)
            this.isLoading = false
            this.changPasswordDialog = false
            this.getUsers()
          } else if (response.status === 'error') {
            this.alertReporter('Алдаа', response.message, response.status)
          }
        })
      }
    }

    // clickCell (row, column, cell, event) {
    //   this.$router.push(
    //     {
    //       name: 'editBrand',
    //       params: {
    //         brand_id: row.brand_id
    //       }
    //     }
    //   )
    // }
  }
}
</script>

<style>

</style>
